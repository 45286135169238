import React from 'react'
import Heading from '../atoms/Heading'
import Text from '../atoms/Text'
import Button from '../atoms/Button'

const LoginOption = ({ handleLoginOptionSelect }) => {
    return (
        <div className='h-full w-3/5 m-auto flex flex-col justify-center items-center p-4 gap-5'>
            <Text className='text-left text-2xl w-full font-semibold'>Login</Text>
            <Text className='text-left  w-full'>Click on the option that you would like to login with</Text>
            <Button type="button"
                imgSrc='/images/icons/message-white.svg'
                imgHeight={20}
                imgClassName='text-white'
                onClick={() => handleLoginOptionSelect('email')}
                imgWidth={20}
                variant="primaryBtn"
                btnClass="text-sm font-normal py-3 px-6 w-full">
                Login with Email
            </Button>
            <div className='w-full flex gap-2 items-center'>
                <hr className='w-1/2'></hr>
                <p className='text-neutral-10'>or</p>
                <hr className='w-1/2'></hr>
            </div>
            <Button type="button"
                variant="primaryBtn"
                imgSrc='/images/icons/call-white.svg'
                imgHeight={20}
                imgWidth={20}
                imgClassName='text-white'
                onClick={() => handleLoginOptionSelect('mobileNumber')}
                btnClass="text-sm font-normal py-3 px-6 w-full">
                Login with Mobile Number
            </Button>
        </div >
    )
}

export default LoginOption