import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import InputLabel from "atoms/InputLabel";
import InputField from "atoms/InputField";
import Button from "atoms/Button";
import Dropdown from "../molecules/Dropdown";
import { getCountryCallingCode } from "react-phone-number-input/input";
import IconWithHeading from 'molecules/IconWithHeading';
import { useFlags } from 'flagsmith/react';

const LoginForm = ({
  handleSubmit,
  control,
  error,
  register,
  loginDetails,
  handleCountryChange,
  countryOptionsList,
  onSubmit,
  country,
  setError,
  onClickBackArrow,
  setValue,
  getValues
}) => {
  const flag = useFlags(['login_page_redesign'])
  const [loginType, setLoginType] = useState(getValues('loginType'))
  const handleMobileNumberChange = (event) => {
    const val = event.target.value.toString();
    if (val.length != 0) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
    setError([]);
  };
  const handleEmailChange = (event) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const val = event.target.value.toString();
    if (emailPattern.test(val)) {
      setDisabled(false);
      return
    }
    setDisabled(true);
    setError([]);
  };
  const checkDisabled = () => {
    if (loginDetails.loginType === "") return true;
    if (loginDetails.loginType === 'mobileNumber' && loginDetails.mobileNumber?.length !== 10) return false
    if (loginDetails.loginType === 'email' && loginDetails.emailId?.length === 0) return false
  }
  const [disabled, setDisabled] = useState(checkDisabled()

  );

  return (
    <div className="md:min-h-screen flex items-center px-6 py-14 bg-white lg:pl-20 lg:pr-32">
      <div className="w-full max-w-md ">
        <div className='flex gap-3'>

          {flag.login_page_redesign.enabled ?
            <Heading type="h4" className="font-bold">
              Login
            </Heading> : <IconWithHeading
              iconUrl="/images/icons/arrow-left-black.svg"
              iconAlt="left arrow"
              iconWidth={15}
              iconHeight={15}
              headingChildren={`Login`}
              onIconClick={onClickBackArrow}
            />
          }

        </div>
        <Text className="mb-6">
          Enter your details to get sign in to your account
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          {loginType === 'mobileNumber' ? <>
            <InputLabel textColor="text-gray-700">Mobile Number</InputLabel>
            <div className="mt-2  flex">
              <Dropdown
                name="countryCode"
                id="countryCode"
                value={country
                }
                onChange={handleCountryChange}
                defaultValue={+getCountryCallingCode("IN")}
                options={countryOptionsList}
                isSearchable={true}

                className={`w-22.5 select-dropdown rounded-none ${error.length != 0 ? " border border-error border-b-error" : ""
                  }`}
                control={control}
              />
              <InputField
                id="mobileNumber"
                name="mobileNumber"
                type="number"
                className="ml-1 rounded-none"
                placeholder="Mobile Number"
                maxLength={10}
                register={register}
                isRequired={true}
                onChangeValue={handleMobileNumberChange}
                variant={error.length != 0 ? "error" : "outlineBottom"}
              />
            </div>
            {error.length != 0 && (
              <div className="text-sm text-error -mt-4 mb-1">
                {error.length != 0 && error}
              </div>
            )}
          </>
            : <>
              <InputLabel textColor="text-gray-700">Email</InputLabel>
              <div className="mt-2  flex">
                <InputField
                  id="emailId"
                  name="emailId"
                  type="email"
                  className="ml-1 rounded-none"
                  placeholder="Email Id"
                  register={register}
                  isRequired={true}
                  onChangeValue={handleEmailChange}
                  variant={error.length != 0 ? "error" : "outlineBottom"}
                />
              </div>
              {error.length != 0 && (
                <div className="text-sm text-error -mt-4 mb-1">
                  {error.length != 0 && error}
                </div>
              )}
            </>}

          <Text className="mb-16 md:mb-10 text-sm text-gray-500">
            {`An OTP will be sent via ${loginType === 'email' ? 'email' : 'sms'} to verify your ${loginType === 'email' ? 'email Id' : 'phone number'}`}
          </Text>
          <Button
            type="submit"
            variant="primaryBtn"
            fontWeight="font-bold"
            btnClass="w-full"
            disabled={disabled}
          >
            
          
            Get {loginType === 'email' ? 'Email' : 'Mobile'} OTP
          </Button>
          {
            flag.login_page_redesign.enabled &&
            <Button
              onClick={() => {
                setLoginType((prev) => {
                  setError([])
                  setDisabled(getValues(prev === 'mobileNumber' ? 'emailId' : 'mobileNumber') === null || getValues(prev === 'mobileNumber' ? 'emailId' : 'mobileNumber') === undefined || getValues(prev === 'mobileNumber' ? 'emailId' : 'mobileNumber') === '')
                  setValue('loginType', prev === 'mobileNumber' ? 'email' : 'mobileNumber')
                  return prev === 'mobileNumber' ? 'email' : 'mobileNumber'

                })
                // setValue('loginType',loginType)  
              }}
              type='button'
              variant="primaryBtn"
              fontWeight="font-bold"
              btnClass="w-full mt-5"

            >
              Login with {loginType === 'mobileNumber' ? 'Email' : 'Mobile Number'}
            </Button>}
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  loginDetails: PropTypes.object,
  error: PropTypes.string,
};
