import React from "react";
import PropTypes from "prop-types";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import Image from "next/image";

const MultiCompanySelect = ({ companyList, onClick }) => {
  const myLoader = ({ src }) => src;
  return (
    <div className="md:min-h-screen flex items-center px-6 py-14 lg:pl-20 lg:pr-32 bg-white">
      <div className="w-full max-w-md">
        <Heading type="h4" className="mb-2 md:mb-8 font-medium">
          Select Company
        </Heading>
        <Text className="mb-6">Select the company you would be working on</Text>
        {companyList.map((company) => (
          <div
            className="flex items-center p-6 rounded border border-gray-100 mb-4 cursor-pointer hover:border-primary-600 hover:bg-primary-50"
            key={companyList.id}
            id={companyList.id}
            onClick={() => onClick(company)}
          >
            <div className="flex items-center shrink-0 mr-4">
              <Image id='img_tag_147'
                src={company.logo}
                loader={myLoader}
                width={52}
                height={52}
                alt={company.companyName}
              />
            </div>
            <Heading type="h5">{company.companyName}</Heading>
          </div>
        ))}
      </div>
    </div>
  );
};

MultiCompanySelect.propTypes = {
  companyLists: PropTypes.array,
  onClick: PropTypes.func,
};

export default MultiCompanySelect;
